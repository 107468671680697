import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Mag: FC<IconSvgProps> = ({ color, clss }) => {
    const styles = {
        svg: {
            verticalAlign: 'middle',
            overflow: 'hidden',
        },
    };

    return (
        <svg className={clss} width="22" height="22" viewBox="0 0 22 22">
            <path
                d="m13.378 2.315-1.182 7.898L19.422 8.9l-8.8 12.785 1.182-7.897-7.225 1.311 8.8-12.784z"
                stroke={color}
                strokeWidth="1.3"
                fill="none"
                fillRule="evenodd"
            />
        </svg>
    );
};

export default Mag;
